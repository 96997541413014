.information-search {
  position: relative;
  padding-top: 5px;
  margin: 0 5px 0;

  > input:focus {
    outline: 1px solid #858585;
  }

  [aria-expanded="true"] {
    z-index: 10;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    box-shadow: -3px 9px 21px -17px rgba(66, 68, 90, 1);
  }

  .result-place {
    position: absolute;
    top: 41px;
    width: var(--info-width);
    background: #fff;
    z-index: 9;
    border: 1px solid #ced4da;
    border-top: 0;
    max-height: var(--info-height);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .result-item {
    // padding: 0.375rem 0.75rem;
    border-top: 1px solid #f1f1f1;
    cursor: pointer;
  }

  .clear-search-information {
    position: absolute;
    top: 8px;
    right: 5px;
    z-index: 999;
    margin: 0;
    padding: 0;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border: 0;
    cursor: pointer;

    &:before {
      content: var(--close-button);
      line-height: 100%;
      display: block;
      top: 0;
    }
  }
}

.active-menu {
  background: #ff9797;
}
